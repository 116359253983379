import { getFilm } from "services/filmsServices";
import { Text, Heading, Button, Img, SelectBox } from "../../components";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ReglementChallenge({
    idsequence = "0",
    idfilm = "0",
    ...props
    }) {

    const [maxclipsequence, setMaxclipsequence] = useState("30s");
    const [maxclip, setMaxclip] = useState("3min");
    const [challenge, setChallenge] = useState("");
	const [ispathe, setIspathe] = useState("");
  
    function formatTime(seconds) {
      if(seconds>9999) return "Aucune limite";
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60; 

      if (minutes > 0) {
          return `${minutes}m ${remainingSeconds > 0 ? `et ${remainingSeconds}s` : ''}`;
      } else {
          return `${remainingSeconds}s`;
      }
    }    
        

  const fetchData = async () => {
    try {
      const videoData = await getFilm(idfilm);

      // Check if videoData and videoData.film are defined
      if (videoData && videoData.film) {
        if(videoData.film){
        	if(videoData.film.producteur=="pathe")setIspathe("et @pathefilms")
        	if(videoData.film.producteur=="studiocanal")setIspathe("et @studiocanalfr")
        	if(videoData.film.producteur=="tandem")setIspathe("et @tandem.films")
        	if(videoData.film.producteur=="adastrafilms")setIspathe("et @adastra_films (@adastrafilms sur Instagram)")
            setMaxclipsequence(formatTime(videoData.film.maxclipsequence));
            setMaxclip(formatTime(videoData.film.maxclip));
            let nameChallenge="#";
            if(videoData.film.challenge){
                nameChallenge+=videoData.film.challenge.title
            }else{
                nameChallenge+=videoData.film.title
            }
            nameChallenge = nameChallenge.replace("##","#");
            setChallenge(nameChallenge)
        }
        
      } else {
        console.error("No film data found for the provided id");
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [idfilm]);

  // Only render the component if `film` data is available
  if (challenge=="") {
    return <></>;
  }

  return (
    <div {...props} className={`${props.className} container p-0 grid grid-cols-12 gap-4 mb-[70px]`}>
      <div className="col-span-4 md:col-span-12 md:flex md:flex-col md:items-start">
        <Heading size="headline_3" as="h3" className="text-[18px] sm:text-[16px] flex">
          Limites de téléchargement
          <Img src="/images/icons/download.svg" alt="" className="ml-[10px] h-[15px] w-[15px] object-contain" />
        </Heading>
        <ul className="list-disc text-[16px] sm:text-[13px] text-[#BBB5BD] pt-[10px] pl-[30px]">
          <li>{maxclipsequence} par Extrait</li>
          <li>{maxclip} par Challenge</li>
        </ul>
      </div>
      <div className="col-span-4 md:col-span-12 md:flex md:flex-col md:items-start">
        <Heading size="headline_3" as="h3" className="text-[18px] sm:text-[16px] flex">
          Obligations 
          <Img src="/images/icons/obligations.svg" alt="" className="ml-[10px] h-[15px] w-[15px] object-contain" />
        </Heading>
        <ul className="list-disc text-[16px] sm:text-[13px] text-[#BBB5BD] pt-[10px] pl-[30px]">
          <li>Ajouter le {challenge} dans la description</li>
          <li>Mentionner @recut.art {ispathe} dans la description</li>
        </ul>
      </div>
      <div className="col-span-4 md:col-span-12 md:flex md:flex-col md:items-start">
        <Heading size="headline_3" as="h3" className="text-[18px] sm:text-[16px] flex">
          Interdictions
          <Img src="/images/icons/Interdictions.svg" alt="" className="ml-[10px] h-[15px] w-[15px] object-contain" />
        </Heading>
        <ul className="list-disc text-[16px] sm:text-[13px] text-[#BBB5BD] pt-[10px] pl-[30px]">
          <li>Aucune utilisation illégale ne sera acceptée (détails disponibles à l’article 3 des <Link to={"/conditions-generales-utilisation?challenge="+idfilm} className="inline" style={{textDecoration:"underline"}}>CGU</Link>)</li>
        </ul>
      </div>
    </div>
  );
}
