import { Heading, Img, Text } from "components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ClipSequence from "./ClipSequence";
import HLSSource from "./HLSSource";
import FilmClipFrame from "./FilmClipFrame";
import { useEffect } from "react";
import { getDureeUserSequences } from "services/userService";

export default function Clip({
  video = null,
  ...props
  }) {
  
  const appUrl = process.env.REACT_APP_URL;
  const [currentQualityVideo, setCurrentQualityVideo] = useState("720p");
  const [dataTimeClipVideo, setDataTimeClipVideo] = useState({timeStart:-1,timeEnd:-1});
  const [disabledClipVideo, setDisabledClipVideo] = useState(false);
  const [maxtimeclip, setMaxtimeclip] = useState(0);
  const adjustVideoQuality = () => {
      if ('connection' in navigator) {
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        
        // Ajustement de la résolution en fonction du type de connexion
        switch (connection.effectiveType) {
          case 'slow-2g':
            setCurrentQualityVideo("144p");
            break;
          case '2g':
            setCurrentQualityVideo("240p");
            break;
          case '3g':
            setCurrentQualityVideo("480p");
            break;
          case '4g':
            setCurrentQualityVideo("1080p");
            break;
          default:
            setCurrentQualityVideo("720p"); // Valeur par défaut pour les connexions inconnues
        }
        
        // Écoute des changements de connexion
        connection.addEventListener('change', adjustVideoQuality);
      } else {
        console.log("L'API Network Information n'est pas prise en charge par ce navigateur.");
      }
  };

  const getDureeUserV1 = async() => {
      const resdataduree = await getDureeUserSequences(video.id,video.film.id);
      let maxtimeclip = resdataduree.maxclipsequence;
      if(resdataduree.maxclip-resdataduree.dureeSequences<maxtimeclip) maxtimeclip = resdataduree.maxclip-resdataduree.dureeSequences;
      if(maxtimeclip<0) maxtimeclip=0;
      const timesequence = convertTimeStringToSeconds(video.timesequence);
      if(maxtimeclip>timesequence)maxtimeclip=timesequence;
      setDataTimeClipVideo({timeStart:0,timeEnd:maxtimeclip});
      setMaxtimeclip(maxtimeclip);
      if(maxtimeclip == 0)setDataTimeClipVideo({timeStart:0,timeEnd:resdataduree.maxclipsequence});
  };

  const getDureeUserV2 = async() => {
    const resdataduree = await getDureeUserSequences(video.id,video.film.id);
    let maxtimeclip = resdataduree.maxclipsequence-resdataduree.dureeSequence;
    if(resdataduree.maxclip-resdataduree.dureeSequences<maxtimeclip) maxtimeclip = resdataduree.maxclip-resdataduree.dureeSequences;
    if(maxtimeclip<0) maxtimeclip=0;
    const timesequence = convertTimeStringToSeconds(video.timesequence);
    if(maxtimeclip>timesequence)maxtimeclip=timesequence;
    setDataTimeClipVideo({timeStart:0,timeEnd:maxtimeclip});
    setMaxtimeclip(maxtimeclip);
    if(maxtimeclip == 0)setDataTimeClipVideo({timeStart:0,timeEnd:resdataduree.maxclipsequence});
  };
  
  const getDureeUser = async() => {
    if(video.film.id==3){
      getDureeUserV1(); //pour film Paul Watson
    }else{
      getDureeUserV2();
    }
  };

  const convertTimeStringToSeconds = (timeString) => {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return (minutes * 60) + seconds;
  };


  useEffect(() => {
    getDureeUser();
    adjustVideoQuality();
  }, []);

    return (
        <div {...props} className={`${props.className} `}>
            <div className="col-span-8 md:col-span-12 block-hlssource">
                <style dangerouslySetInnerHTML={{__html: `
                .block-hlssource .video-react-play-control {
                    color: ${video.film.color};
                }
                `}} />
                <HLSSource 
                    video={video}
                    currentQualityVideo={currentQualityVideo}
                    setCurrentQualityVideo={setCurrentQualityVideo}
                    dataTimeClipVideo={dataTimeClipVideo}
                    setDataTimeClipVideo={setDataTimeClipVideo}
                    disabledClipVideo={disabledClipVideo}
                    src={appUrl+"public/hls/sequence_"+video?.id+"/720p.m3u8"}
                />
                <FilmClipFrame
                    idsequence={video.id}
                    filmColor={video.film.color}
                    timesequence={video.timesequence}
                    dataTimeClipVideo={dataTimeClipVideo}
                    setDataTimeClipVideo={setDataTimeClipVideo}
                    setDisabledClipVideo={setDisabledClipVideo}
                    maxtimeclip={maxtimeclip}
                    className=" md:gap-[13px] relative component-blockfilmclipframe" 
                />
            </div>
            <div className="col-span-4 md:col-span-12 items-center justify-center flex">
                <div className="items-start justify-center flex flex-col w-[90%] max-w-[400px] pl-8 sm: pl-0 sm:pt-4" >
                    <div className="flex items-start">
                        <Img src={"/images/logo-"+(video && video.producteur? video.producteur :video?.film.producteur)+"-sequences.png"} className="w-[40px] mr-[10px]"></Img>
                        <div className="flex flex-col items-start">
                            <Heading size="headline_3" as="h3" className="text-[32px] sm:text-[16px]">
                            {video?.title}
                            </Heading>
                            <Link to={"/"+video?.film.producteur+"/"+video?.film.pseudo}>
                            <Text as="p" className="text-[19px] sm:text-[14px]">{video?.subtitle}</Text>
                            </Link>
                        </div>
                    </div>
                    <ClipSequence 
                        idsequence={video.id} 
                        filmId={video.filmId} 
                        title={video.title}
                        timesequence={video.timesequence}
                        maxDureeClips={video.film.maxclip}
                        filmColor={video.film.color}
                        currentQualityVideo={currentQualityVideo}
                        setCurrentQualityVideo={setCurrentQualityVideo}
                        dataTimeClipVideo={dataTimeClipVideo}
                        setDataTimeClipVideo={setDataTimeClipVideo}
                        setMaxtimeclip={setMaxtimeclip}
                        maxtimeclip={maxtimeclip}
                        className=" md:gap-[13px] relative w-[100%] component-ClipSequence" 
                    />
                </div>
            </div>
        </div>
    );
}
